import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { useNavigate, useParams } from 'react-router-dom';

import { RouteParamId } from '@serenityapp/domain';
import { LocationCreateEditFormValues } from '@serenityapp/components-react-common';
import { getIsLocationUpdating, locationUpdate } from '@serenityapp/redux-store';
import { useMakeTestId } from '@serenityapp/components-react-web';

import { FilterCreateEditForm } from './components';
import { useLocationDetail } from './hooks';

const FilterEditDrawer = () => {
  const makeTestId = useMakeTestId('FilterEditDrawer');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams<RouteParamId>() as RouteParamId;

  const {
    location: filter,
    generalChannels: currentGeneralChannels,
    residentChannels: currentResidentChannels,
    isLoading,
  } = useLocationDetail(id);
  const isUpdating = useSelector(getIsLocationUpdating);

  const initialValues = {
    name: filter?.name ?? '',
    description: filter?.description ?? '',
    filterPositionPriority: filter?.filterPositionPriority ?? 0,
    generalChannels: currentGeneralChannels,
    residentChannels: currentResidentChannels,
  };

  const goBack = () => navigate('..');

  const handleFormSubmit = (values: LocationCreateEditFormValues) => {
    const selectedChannels = [
      ...(values.residentChannels || []),
      ...(values.generalChannels || []),
    ];

    const locationToUpdate = {
      id,
      name: values.name?.trim(),
      description: values.description?.trim(),
      filterPositionPriority: values.filterPositionPriority,
      conversations: R.pluck('id', selectedChannels),
    };

    dispatch(locationUpdate({ location: locationToUpdate, onFailed: goBack, onSuccess: goBack }));
  };

  return (
    <FilterCreateEditForm
      isEditMode
      dataTestId={makeTestId('')}
      filterId={filter?.id}
      filterName={filter?.name}
      handleFormClose={goBack}
      handleFormSubmit={handleFormSubmit}
      initialValues={initialValues}
      isLoading={isLoading}
      isSaving={isUpdating}
      title={`Edit filter - ${filter?.name}`}
    />
  );
};

export default FilterEditDrawer;
