import { Schema } from '@serenityapp/domain';
import { Cache, ResolveInfo } from '@urql/exchange-graphcache';
import { addLocation } from './utils';
import { Building } from '@serenityapp/api-graphql';

export default function buildingCreate(
  result: Building.BuildingApi.BuildingCreateMutationResult,
  _args: Schema.Building.Create.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const createdBuildingOutput = result?.result;

  if (info.error || !createdBuildingOutput || !createdBuildingOutput.success) {
    return;
  }

  const { organizationId, displayName, id, name, edited } = createdBuildingOutput.item;

  const location: Schema.Location.ItemWithTypename = {
    id,
    displayName: displayName ?? 'Building',
    kind: 'Building',
    edited,
    name,
    __typename: 'Building',
  };

  addLocation(location, organizationId, cache);
}
