import { useState } from 'react';
import { useMutation } from 'urql';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  List,
  ListItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Schema } from '@serenityapp/domain';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { Location } from '@serenityapp/api-graphql';
import { ClientApi } from '@serenityapp/api-client-graph';
import type { BuildingLocationGroupConvertMutationResult } from '@serenityapp/api-client-graph/src/building/api';
import type { LocationGroupBuildingConvertMutationResult } from '@serenityapp/api-client-graph/src/location-group/api';

import { listSx, listItemSx, boldTextSx } from './styles';
import { snackAdd } from '@serenityapp/redux-store';
import { useDispatch } from 'react-redux';

type ConfirmTypeConversionDialogProps = {
  dataTestId?: string;
  id: string;
  oldLocationType: string;
  newLocationType: string;
  location: Location.LocationApi.LocationGroupWithTypename;
  isOpen?: boolean;
  onClose: () => void;
  onCancel: () => void;
};

const ConfirmTypeConversionDialog = ({
  dataTestId,
  id,
  location,
  oldLocationType,
  newLocationType,
  isOpen = false,
  onClose,
  onCancel,
}: ConfirmTypeConversionDialogProps) => {
  const makeTestId = useMakeTestId('ConfirmTypeConversionDialog', dataTestId);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const { setFieldValue } = useFormikContext();
  const [, buildingToLocationGroup] = useMutation<
    BuildingLocationGroupConvertMutationResult,
    Schema.Building.LocationGroupConvert.Variables
  >(ClientApi.Building.Api.buildingLocationGroupConvertMutation);

  const [, locationGroupToBuilding] = useMutation<
    LocationGroupBuildingConvertMutationResult,
    Schema.LocationGroup.BuildingConvert.Variables
  >(ClientApi.LocationGroup.Api.locationGroupBuildingConvertMutation);

  const handleConfirmCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleSuccess = (item: Schema.Building.Item | Schema.LocationGroup.Item) => {
    setFieldValue('locationType', item.kind);
    navigate(`../${item.id}`, { replace: true });
    setIsLoading(false);
    onClose();
    dispatch(snackAdd({ message: `Location type was successfully converted`, type: 'success' }));
  };

  const handleError = () => {
    setIsLoading(false);
    onClose();
    dispatch(snackAdd({ message: `Error converting location type`, type: 'error' }));
  };

  const handleLocationTypeChange = () => {
    setIsLoading(true);
    // Building to LocationGroup
    if (newLocationType === 'Location Group' && oldLocationType === 'Building') {
      buildingToLocationGroup({
        input: {
          id,
        },
      }).then((result) => {
        const locationOutputResult = result.data?.result;
        if (result.error || !locationOutputResult || locationOutputResult.success === false) {
          handleError();
        } else {
          handleSuccess(locationOutputResult.item);
        }
      });
    }

    // LocationGroup to Building
    if (newLocationType === 'Building' && oldLocationType === 'Location Group') {
      locationGroupToBuilding({
        input: {
          id,
        },
      }).then((result) => {
        const locationOutputResult = result.data?.result;
        if (result.error || !locationOutputResult || locationOutputResult.success === false) {
          handleError();
        } else {
          handleSuccess(locationOutputResult.item);
        }
      });
    }

    return null;
  };

  // Count units for each building in the location group
  const countUnits = (
    location: Location.LocationApi.LocationGroupWithTypename,
  ): {
    [key: string]: number;
  } => {
    const unitCount: { [key: string]: number } = {};

    location?.locations?.edges.forEach((edge) => {
      const location = edge.location;
      if (location.kind === 'Unit') {
        const parentId = location.parentId || '';
        if (!unitCount[parentId]) {
          unitCount[parentId] = 0;
        }
        unitCount[parentId]++;
      }
    });

    return unitCount;
  };

  // Function to format the result
  const formatLocationGroupBuildings = (
    unitCount: { [key: string]: number },
    location: Location.LocationApi.LocationGroupWithTypename,
  ): string[] | undefined => {
    return location?.locations?.edges
      .filter((edge) => edge.location.kind === 'Building')
      .map((edge) => {
        const building = edge.location;
        const units = unitCount[building.id] || 0;
        return units > 0
          ? `${building.name} (${units} Unit${units > 1 ? 's' : ''})`
          : `${building.name}`;
      });
  };

  const unitsCount = countUnits(location);
  const formattedLocationGroupBuildings = formatLocationGroupBuildings(unitsCount, location);

  return (
    <Dialog
      fullWidth
      data-testid={makeTestId('')}
      open={isOpen}
      onClick={(event) => event.stopPropagation()}
      onClose={onClose}
    >
      <DialogTitle>
        Convert {oldLocationType} to {newLocationType}
      </DialogTitle>
      <DialogContent>
        {formattedLocationGroupBuildings && formattedLocationGroupBuildings.length > 0 ? (
          <>
            <Typography>
              Converting{' '}
              <Typography component="span" sx={boldTextSx} variant="subtitle1">
                {location?.name}
              </Typography>{' '}
              location group to a building will result in the following buildings no longer being
              part of the location group:
            </Typography>
            <List dense sx={listSx}>
              {formattedLocationGroupBuildings.map((item, index) => (
                <ListItem key={index} sx={[boldTextSx, listItemSx]}>
                  {item}
                </ListItem>
              ))}
            </List>
          </>
        ) : null}
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                data-testid={makeTestId('confirm-convert-checkbox')}
                checked={checked}
                onChange={handleConfirmCheckbox}
              />
            }
            label={`Are you sure you want to convert ${oldLocationType} to ${newLocationType}`}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('cancel')} onClick={onCancel}>
          Cancel
        </Button>
        <LoadingButton
          data-testid={makeTestId('confirm-convert-button')}
          disabled={!checked}
          loading={isLoading}
          variant="contained"
          onClick={handleLocationTypeChange}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmTypeConversionDialog;
