import { organizationAlexaDeviceSettingsPoliciesQuery } from '@serenityapp/api-graphql';
import { SelectorItemProps } from '@serenityapp/components-react-common';
import { Schema } from '@serenityapp/domain';
import { useQuery } from 'urql';

const useAlexaDeviceSettingsPolicies = (
  organizationId: string,
): [
  Array<SelectorItemProps>,
  boolean,
  { [key: string]: Schema.AlexaDeviceSettingsPolicy.Item },
] => {
  const [result] = useQuery<Schema.Organization.AlexaDeviceSettingsPolicies.QueryResult>({
    query: organizationAlexaDeviceSettingsPoliciesQuery,
    variables: {
      input: { id: organizationId },
    },
    requestPolicy: 'cache-and-network',
  });

  const { edges = [] } = result?.data?.organization?.alexaDeviceSettingsPolicies || {};
  const policiesAsSelectorItems = edges?.map(
    (edge: Schema.Organization.AlexaDeviceSettingsPolicies.Edge) => ({
      id: edge.alexaDeviceSettingsPolicy.id,
      label: edge.alexaDeviceSettingsPolicy.name,
    }),
  );

  const indexedAlexaDeviceSettingsPolicies = edges.reduce(
    (
      acc: Record<string, Schema.AlexaDeviceSettingsPolicy.Item>,
      edge: Schema.Organization.AlexaDeviceSettingsPolicies.Edge,
    ) => {
      acc[edge.alexaDeviceSettingsPolicy.id] = edge.alexaDeviceSettingsPolicy;
      return acc;
    },
    {},
  );

  return [policiesAsSelectorItems, result.fetching, indexedAlexaDeviceSettingsPolicies];
};

export default useAlexaDeviceSettingsPolicies;
