import { ClientApi } from '@serenityapp/api-client-graph';

export const DEFAULT_DEVICES_PAGE_SIZE = 25;

export const PAGE_SIZE_OPTIONS = [DEFAULT_DEVICES_PAGE_SIZE, 50, 100];

// Type guards
export const isAlexaDevice = (
  device?:
    | ClientApi.AlexaDevice.Fragments.DetailType
    | ClientApi.DigitalSign.Fragments.DetailType,
): device is ClientApi.AlexaDevice.Fragments.DetailType => {
  return device?.__typename === 'AlexaDevice';
};
