import { useMemo } from 'react';
import { useQuery, type UseQueryArgs } from 'urql';

import { UserKind, ViewerOrganizationUsersVariables } from '@serenityapp/domain';
import { ServiceLevel, ServiceLevelFn, StringFn } from '@serenityapp/core';
import { SelectorItemProps } from '@serenityapp/components-react-common';
import {
  viewerOrganizationUsersQuery,
  ViewerOrganizationUsersQueryResult,
} from '@serenityapp/api-graphql';

import { useCurrentUser } from '../../../common/hooks';

const useUsers = () => {
  const currentUser = useCurrentUser();
  const organizationId = currentUser?.orgId || '';

  const usersQueryOptions = useMemo(
    (): UseQueryArgs<ViewerOrganizationUsersVariables> => ({
      query: viewerOrganizationUsersQuery,
      requestPolicy: 'cache-and-network',
      variables: {
        organizationInput: { orgId: organizationId },
        usersInput: { kinds: [UserKind.RESIDENT] },
      },
      pause: !organizationId,
    }),
    [organizationId],
  );

  const [usersResult] = useQuery<
    ViewerOrganizationUsersQueryResult,
    ViewerOrganizationUsersVariables
  >(usersQueryOptions);
  const { data: usersData, fetching: usersFetching } = usersResult;

  // Transform building data to options right after fetching
  const usersOptions: SelectorItemProps[] = useMemo(() => {
    return (
      usersData?.viewer?.organization?.result?.edges.map((edge) => ({
        id: edge.node.id,
        label: edge.node.fullName || edge.node.name || '',
        subtitle: ServiceLevelFn.abbreviation(edge.node.serviceLevel as ServiceLevel),
        avatar: {
          src: edge.node.avatar?.url,
          alt: edge.node.fullName || edge.node.name || '',
          initials:
            edge.node.initials || StringFn.initials(edge.node?.fullName || edge.node?.name || ''),
        },
      })) || []
    );
  }, [usersData]);

  return { usersData, usersOptions, usersFetching };
};

export default useUsers;
