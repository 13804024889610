import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';
import { useDispatch } from 'react-redux';

import { AddressFn } from '@serenityapp/core';
import { Schema } from '@serenityapp/domain';
import { ClientApi } from '@serenityapp/api-client-graph';
import { Building, Unit } from '@serenityapp/api-graphql';
import { SelectorItemProps } from '@serenityapp/components-react-common';
import { snackAdd } from '@serenityapp/redux-store';

import { useCampus } from './hooks';
import { LocationForm } from './components';
import {
  MULTI_PURPOSE_SERVICE_LEVEL,
  getLocationTypeLabelById,
  getServiceLevelsFromUsers,
} from './utils';

const LocationCreateDrawer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [, createBuilding] = useMutation<
    Building.BuildingApi.BuildingCreateMutationResult,
    Schema.Building.Create.Variables
  >(Building.BuildingApi.buildingCreateMutation);

  const [, createUnit] = useMutation<
    Unit.Api.UnitCreateMutationResult,
    Schema.Unit.Create.Variables
  >(Unit.Api.unitCreateMutation);

  const { campus, isCampusFetching } = useCampus();

  const [, createLocationGroup] = useMutation<
    ClientApi.LocationGroup.Api.LocationGroupCreateMutationResult,
    Schema.LocationGroup.Create.Variables
  >(ClientApi.LocationGroup.Api.locationGroupCreate);

  const handleSuccess = (locationType: string) => {
    setIsLoading(false);
    navigate('../');
    dispatch(snackAdd({ message: `${locationType} was successfully created`, type: 'success' }));
  };

  const handleError = (locationType: string) => {
    setIsLoading(false);
    dispatch(snackAdd({ message: `Error creating ${locationType}`, type: 'error' }));
  };

  const handleFormSubmit = (values: any) => {
    if (!campus) return;
    setIsLoading(true);
    const locationType = values.locationType;
    const locationTypeLabel = getLocationTypeLabelById(locationType);

    const shouldIncludePrefilledCountry =
      values.addressLine1 ||
      values.addressLine2 ||
      values.city ||
      values.stateOrRegion ||
      values.zipCode;

    /**
     * Rob: Address is "all or nothing." It can be undefined, but if it is defined, all fields
     * must be present.
     */
    const address = AddressFn.optional({
      ...values,
      countryOrRegion: shouldIncludePrefilledCountry && values.countryOrRegion,
    });

    switch (locationType) {
      case 'Building': {
        const isLocationGroupSelected = !!values?.locationGroupId;

        const floors = values.floors.map((floor: any) => ({
          floorName: floor?.name || undefined,
          floorNumber: floor.floorNumber,
        }));

        createBuilding({
          input: {
            name: values.locationName,
            displayName: values.displayName.length > 0 ? values.displayName : 'Building',
            parentKind: isLocationGroupSelected ? 'LocationGroup' : 'Campus',
            parentId: isLocationGroupSelected ? values.locationGroupId : campus?.id,
            floors,
            address,
          },
        }).then((result) => {
          if (result.error || result.data?.result?.success === false) {
            handleError(locationTypeLabel);
          } else {
            handleSuccess(locationTypeLabel);
          }
        });
        break;
      }

      case 'Unit': {
        let parentKind: Schema.Location.Kind;
        let parentId: string;

        // Determine parentKind and parentId based on the presence of floorId and buildingId
        if (values?.floorId) {
          parentKind = 'Floor';
          parentId = values.floorId;
        } else if (values?.buildingId) {
          parentKind = 'Building';
          parentId = values.buildingId;
        } else if (values?.locationGroupId) {
          parentKind = 'LocationGroup';
          parentId = values.locationGroupId;
        } else {
          // If none is defined
          parentKind = 'Campus';
          parentId = campus.id;
        }

        let serviceLevels;

        if (values.serviceLevel === MULTI_PURPOSE_SERVICE_LEVEL) {
          serviceLevels = getServiceLevelsFromUsers(values.users);
        } else if (values.serviceLevel === '') {
          serviceLevels = undefined;
        } else {
          serviceLevels = [values.serviceLevel];
        }

        createUnit({
          input: {
            name: values.locationName,
            displayName: values.displayName.length > 0 ? values.displayName : 'Unit',
            parentKind,
            parentId,
            serviceLevels,
            userIds: values.users?.map((user: SelectorItemProps) => user.id),
            address,
            deviceIds: values.devices?.map((device: SelectorItemProps) => device.id),
          },
        }).then((result) => {
          if (result.error || result.data?.result?.success === false) {
            handleError(locationTypeLabel);
          } else {
            handleSuccess(locationTypeLabel);
          }
        });
        break;
      }

      case 'LocationGroup':
        createLocationGroup({
          input: {
            name: values.locationName,
            displayName: 'Location Group',
            parentKind: 'Campus',
            parentId: campus.id,
          },
        }).then((result) => {
          if (result.error || result.data?.result?.success === false) {
            handleError(locationTypeLabel);
          } else {
            handleSuccess(locationTypeLabel);
          }
        });
        break;

      default:
        break;
    }
  };

  const initialValues = {
    locationName: '',
    locationType: '',
    locationTypeLiteral: '',
    displayName: '',
    // Unit
    pccRecordStamp: '',
    serviceLevel: '',
    users: [],
    devices: [],
    // Address fields
    countryOrRegion: 'United States',
    city: '',
    stateOrRegion: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    // Buildings
    floors: [],
    // parent locations
    floorId: '',
    floor: null,
    buildingId: '',
    building: null,
    locationGroup: null,
    locationGroupId: '',
  };

  return (
    <LocationForm
      initialValues={initialValues}
      isFetching={isCampusFetching}
      isLoading={isLoading}
      onFormSubmit={handleFormSubmit}
    />
  );
};

export default LocationCreateDrawer;
