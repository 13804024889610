import { SelectorItemProps } from '@serenityapp/components-react-common';
import { ServiceLevelFn } from '@serenityapp/core';
import { useFormikContext } from 'formik';
import { useState, useEffect } from 'react';
import { UnitFormValues } from '../schema';
import { MULTI_PURPOSE_SERVICE_LEVEL } from '../utils';
import { SearchSelectorField } from '@serenityapp/components-react-web';

type UserSearchSelectorFieldProps = {
  usersOptions: SelectorItemProps[];
  loading?: boolean;
};

const UserSearchSelectorField = ({
  usersOptions,
  loading = false,
}: UserSearchSelectorFieldProps) => {
  const { values } = useFormikContext<UnitFormValues>();

  const [errors, setErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    if (values.serviceLevel && values.serviceLevel !== MULTI_PURPOSE_SERVICE_LEVEL) {
      const mismatchErrors = (values.users || []).reduce(
        (acc: Record<string, string>, user: SelectorItemProps) => {
          if (ServiceLevelFn.getFromString(user.subtitle) !== values.serviceLevel) {
            return {
              ...acc,
              [user.id]:
                "Notice: The resident's service level differs from the location unit's service level.",
            };
          }
          return acc;
        },
        {},
      );
      setErrors(mismatchErrors);
    } else {
      setErrors({});
    }
  }, [values.serviceLevel, values.users]);

  return (
    <SearchSelectorField
      customErrors={errors}
      items={usersOptions}
      label="Residents"
      name="users"
      loading={loading}
    />
  );
};

export default UserSearchSelectorField;
