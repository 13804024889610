import { Box, Divider, ListItem, ListItemText, Skeleton, Typography } from '@mui/material';

const DeviceEditDrawerSkeleton = () => (
  <Box sx={generalSectionSx}>
    <Typography display="block" variant="overline">
      General
    </Typography>
    <Divider sx={dividerSx} />
    <Box sx={listItemsWrapperSx}>
      <ListItem disableGutters>
        <ListItemText primary={<Skeleton width="60%" />} secondary="Connection status" />
      </ListItem>
      <ListItem disableGutters>
        <ListItemText primary={<Skeleton width="70%" />} secondary="Device Serial" />
      </ListItem>
    </Box>
    <Box sx={listItemsWrapperSx}>
      <ListItem disableGutters>
        <ListItemText primary={<Skeleton width="50%" />} secondary="Software version" />
      </ListItem>
      <ListItem disableGutters>
        <ListItemText primary={<Skeleton width="60%" />} secondary="MAC Address" />
      </ListItem>
    </Box>
    <Box sx={listItemsWrapperSx}>
      <ListItem disableGutters>
        <ListItemText primary={<Skeleton width="50%" />} secondary="Model" />
      </ListItem>
    </Box>
  </Box>
);

const dividerSx = {
  mb: 2.25,
};

const listItemsWrapperSx = {
  display: 'flex',
  mb: 1,
};

const generalSectionSx = {
  mb: 4,
  mt: 3,
};

export default DeviceEditDrawerSkeleton;
