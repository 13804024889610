import { ChangeEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikProps, FormikValues } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Typography, Chip } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import {
  FormikField,
  Form,
  Drawer,
  SelectField,
  useMakeTestId,
} from '@serenityapp/components-react-web';
import { useIsFeatureEnabled } from '@serenityapp/redux-store';
import { Location } from '@serenityapp/api-graphql';

import FormikDrawer from '../../components/FormikDrawer';
import { validationSchema } from '../schema';
import { locationTypes, getLocationTypeLabelById } from '../utils';

import { dividerSx, headerInfoSx } from './styles';
import BuildingFields from './BuildingFields';
import UnitFields from './UnitFields';
import LocationActionsMenu from '../LocationsActionMenu';
import ConfirmTypeConversionDialog from './ConfirmTypeConversionDialog';
import validateWithZod from '../../../../utils/validate-formik-with-zod';

type LocationFormProps = {
  initialValues: FormikValues;
  location?: Location.LocationApi.Location;
  isFetching: boolean;
  editMode?: boolean;
  isLoading: boolean;
  onFormSubmit: (values: FormikValues) => void;
};

const LocationForm = ({
  initialValues,
  isFetching,
  location,
  isLoading,
  editMode = false,
  onFormSubmit,
}: LocationFormProps) => {
  const navigate = useNavigate();
  const isPCCFeatureEnabled = useIsFeatureEnabled('pcc');
  const makeTestId = useMakeTestId('LocationForm');

  const [shouldOpenTypeConversionWarning, setShouldOpenTypeConversionWarning] = useState(false);
  const [locationTypeToChange, setLocationTypeToChange] = useState(initialValues.locationType);

  const shouldDisplayActionsMenu = editMode;

  const [actionsMenuAnchor, setActionsMenuAnchor] = useState<null | HTMLElement>(null);
  const openActionsMenu = (event: React.MouseEvent<HTMLElement>) =>
    setActionsMenuAnchor(event.currentTarget);

  const goBack = () => {
    navigate('..');
  };

  const onClose = useCallback(() => {
    navigate('..');
  }, [navigate]);

  const closeActionsMenu = () => {
    setActionsMenuAnchor(null);
  };

  const shouldAllowConversion =
    (initialValues.locationType === 'Building' ||
      initialValues.locationType === 'LocationGroup') &&
    isPCCFeatureEnabled;

  const renderDrawerBody = ({
    submitForm,
    setValues,
    values,
    dirty,
    isValid,
  }: FormikProps<FormikValues>) => {
    const enableSave = dirty && isValid;

    const locationTypesItems = shouldAllowConversion
      ? locationTypes.map((locationType) => {
          if (editMode && isPCCFeatureEnabled && locationType.id === 'Unit')
            return {
              ...locationType,
              disabled: true,
              tooltip: `${values.locationType} cannot be converted to Unit`,
            };
          return locationType;
        })
      : locationTypes;

    const renderLocationTypeFields = () => {
      switch (values.locationType) {
        case 'Building':
          return <BuildingFields />;
        case 'Unit':
          return <UnitFields isEditMode={editMode} />;
        default:
          return null;
      }
    };

    const handleLocationTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (editMode && isPCCFeatureEnabled) {
        setLocationTypeToChange(event.target.value);
        setShouldOpenTypeConversionWarning(true);
        return;
      }
      setValues((prevValues) => ({
        ...prevValues,
        locationType: event.target.value,
        locationTypeLiteral: event.target.value,
        displayName: '',
      }));
    };

    const handleCancelingTypeConversion = () => {
      setShouldOpenTypeConversionWarning(false);
    };

    return (
      <>
        <Drawer.Header loading={isFetching || isLoading} dataTestId={makeTestId('')}>
          <Box sx={headerInfoSx}>
            <Typography noWrap variant="h6">
              {editMode ? `Edit location ${initialValues?.locationName}` : 'New location'}
            </Typography>
            {editMode && values.locationType && <Chip label={values.locationType} size="small" />}
          </Box>
          {shouldDisplayActionsMenu && (
            <>
              <Button
                color="neutral"
                data-testid={makeTestId('actions')}
                disabled={isLoading}
                endIcon={<KeyboardArrowDown sx={actionsButtonIconSx} />}
                sx={actionsButtonSx}
                onClick={openActionsMenu}
              >
                Actions
              </Button>
              <Divider orientation="vertical" sx={headerDividerSx} />
            </>
          )}
        </Drawer.Header>
        <Drawer.Content dataTestId={makeTestId('')}>
          <Form disabled={isLoading || isFetching}>
            <Typography display="block" variant="overline" sx={{ mt: 3 }}>
              general
            </Typography>
            <Divider sx={dividerSx} />
            <FormikField
              dataTestId={makeTestId('LocationName')}
              label="Location name"
              name="locationName"
              type="text"
            />
            <SelectField
              dataTestId={makeTestId('LocationType')}
              displayTooltips
              disabled={editMode && !shouldAllowConversion}
              items={locationTypesItems}
              label="Location type"
              name="locationType"
              onChange={handleLocationTypeChange}
            />
            {renderLocationTypeFields()}
          </Form>
        </Drawer.Content>
        <Drawer.Footer dataTestId={makeTestId('')}>
          <LoadingButton
            data-testid={makeTestId('save')}
            disabled={!enableSave}
            loading={isLoading || isFetching}
            variant="contained"
            onClick={submitForm}
          >
            Save
          </LoadingButton>
        </Drawer.Footer>
        {shouldOpenTypeConversionWarning && (
          <ConfirmTypeConversionDialog
            id={values.id}
            isOpen={shouldOpenTypeConversionWarning}
            oldLocationType={getLocationTypeLabelById(values.locationType)}
            newLocationType={getLocationTypeLabelById(locationTypeToChange)}
            location={location as Location.LocationApi.LocationGroupWithTypename}
            onCancel={handleCancelingTypeConversion}
            onClose={handleCancelingTypeConversion}
          />
        )}
      </>
    );
  };

  return (
    <>
      {editMode && (
        <LocationActionsMenu
          anchorEl={actionsMenuAnchor}
          locationId={initialValues.id}
          onActionSuccess={goBack}
          onClose={closeActionsMenu}
        />
      )}
      <FormikDrawer
        dataTestId={makeTestId('')}
        shouldConfirmClose
        initialValues={initialValues}
        validateForm={validateWithZod(validationSchema)}
        onClose={onClose}
        onSubmit={onFormSubmit}
      >
        {renderDrawerBody}
      </FormikDrawer>
    </>
  );
};

const actionsButtonSx = {
  color: 'text.primary',
  textTransform: 'capitalize',
  mr: 1,
};

const actionsButtonIconSx = {
  color: 'action.active',
};

const headerDividerSx = {
  height: '40px',
};

export default LocationForm;
