import { Box, Divider, ListItem, ListItemText, Typography } from '@mui/material';
import { FormikField, useMakeTestId } from '@serenityapp/components-react-web';
import { FormikValues, useFormikContext } from 'formik';
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material';

const DeviceFields = () => {
  const makeTestId = useMakeTestId('DeviceFields');

  const { values } = useFormikContext<FormikValues>();

  return (
    <>
      <Box sx={generalSectionSx}>
        <Typography display="block" variant="overline">
          General
        </Typography>
        <Divider sx={dividerSx} />
        <Box sx={listItemsWrapperSx}>
          <ListItem disableGutters>
            <ListItemText
              primary={
                <Box>
                  {values.online === null || values.online === undefined ? (
                    <Typography data-testid={makeTestId('online-status-not-available')}>
                      N/A
                    </Typography>
                  ) : values.online ? (
                    <Box sx={onlineStatusSx} data-testid={makeTestId('online-status-online')}>
                      <CheckCircleOutline color="success" />
                      <Typography>Online</Typography>
                    </Box>
                  ) : (
                    <Box sx={onlineStatusSx} data-testid={makeTestId('online-status-offline')}>
                      <CancelOutlined color="error" />
                      <Typography>Offline</Typography>
                    </Box>
                  )}
                </Box>
              }
              secondary="Connection status"
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText
              primary={values.serialNumber ? values.serialNumber : 'N/A'}
              secondary="Device Serial"
            />
          </ListItem>
        </Box>
        <Box sx={listItemsWrapperSx}>
          <ListItem disableGutters>
            <ListItemText
              data-testid={makeTestId('software-version')}
              primary={values.softwareVersion ? values.softwareVersion : 'N/A'}
              secondary="Software version"
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemText
              data-testid={makeTestId('mac-address')}
              primary={values.macAddress ? values.macAddress : 'N/A'}
              secondary="MAC Address"
            />
          </ListItem>
        </Box>
        <Box sx={listItemsWrapperSx}>
          <ListItem disableGutters>
            <ListItemText
              data-testid={makeTestId('model')}
              primary={values.model ? values.model : 'N/A'}
              secondary="Model"
            />
          </ListItem>
        </Box>
      </Box>
      <FormikField label="Device name" name="name" dataTestId={makeTestId('name')} />
      <FormikField
        label="Location"
        name="locationName"
        value={values.locationName ? values.locationName : 'N/A'}
        disabled
        dataTestId={makeTestId('locationName')}
      />
    </>
  );
};

const dividerSx = {
  mb: 2.25,
};

const listItemsWrapperSx = {
  display: 'flex',
  mb: 1,
};

const generalSectionSx = {
  mb: 4,
  mt: 3,
};

const onlineStatusSx = {
  display: 'flex',
  gap: 1,
  alignItems: 'center',
};

export default DeviceFields;
