import { OperationDefinitionNode } from 'graphql';
import { CombinedError, mapExchange as mapExchangeFn, Operation, OperationResult } from 'urql';
import * as Sentry from '@sentry/react';

class UrqlError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UrqlError';
  }
}

const mapExchange = mapExchangeFn({
  // The onError callback is called whenever an error occurs in graphql syntax, schema validation or network errors
  onError(error: CombinedError, operation: Operation) {
    // Get the operation name: the name of the query or mutation
    const operationName = (operation.query.definitions[0] as OperationDefinitionNode)?.name
      ?.value;

    // Get variables that were sent with the request
    const variables = operation.variables;

    const fetchOptions = operation?.context?.fetchOptions;
    const headers =
      typeof fetchOptions === 'function' ? fetchOptions().headers : fetchOptions?.headers;

    const urqlError = new UrqlError('GraphQL Operation Failed: ' + error.message);

    Sentry.captureException(urqlError, {
      extra: {
        variables,
        operationName,
        output: error,
        headers,
      },
      tags: {
        urql: 'urql',
      },
      fingerprint: ['{{ default }}', 'urql', error.message],
    });
  },
  // The onResult callback is called whenever an OperationResult is received.
  // An OperationResult contains the data or error returned from the server in response to an operation.
  // if an error occurs in the mutation logic, result will contain msg, code and success = false
  // we need to log this kind of errors to sentry
  onResult(result: OperationResult) {
    if (result.data && result.data.result && result.data.result.success === false) {
      // Get the operation name
      const operation = result.operation.query.definitions[0] as OperationDefinitionNode;
      const operationName = operation?.name?.value;

      // Get variables that were sent with the request
      const variables = result.operation.variables;

      // Handle fetchOptions, check if it's a function or an object
      const fetchOptions = result?.operation?.context?.fetchOptions;
      const headers =
        typeof fetchOptions === 'function' ? fetchOptions().headers : fetchOptions?.headers;
      const urqlError = new UrqlError(result.data.result.msg);

      Sentry.captureException(urqlError, {
        extra: {
          variables,
          operationName,
          output: result,
          headers,
        },
        fingerprint: ['{{ default }}', 'urql', result.data.result.msg],
      });
    }
  },
});

export default mapExchange;
