import { Cache, ResolveInfo } from '@urql/exchange-graphcache';
import { Schema } from '@serenityapp/domain';
import { removeLocations } from './utils';
import { LocationGroup } from '@serenityapp/api-graphql';

export default function locationGroupRemove(
  result: LocationGroup.Api.LocationGroupRemoveMutationResult,
  _args: Schema.LocationGroup.Remove.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const removedLocationGroupOutput = result?.result;

  if (info.error || !removedLocationGroupOutput || !removedLocationGroupOutput.success) {
    return;
  }

  const locationsToRemove = removedLocationGroupOutput.deletedLocationIds;
  const organizationId = removedLocationGroupOutput.organizationId;

  removeLocations(locationsToRemove, organizationId, cache);
}
