import { Cache, ResolveInfo } from '@urql/exchange-graphcache';
import { Schema } from '@serenityapp/domain';
import { removeLocations } from './utils';
import { Building } from '@serenityapp/api-graphql';

export default function buildingRemove(
  result: Building.BuildingApi.BuildingRemoveMutationResult,
  _args: Schema.Building.Remove.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const removedBuildingOutput = result?.result;

  if (info.error || !removedBuildingOutput || !removedBuildingOutput.success) {
    return;
  }

  const locationsToRemove = removedBuildingOutput.deletedLocationIds;
  const organizationId = removedBuildingOutput.organizationId;

  removeLocations(locationsToRemove, organizationId, cache);
}
