import { useQuery } from 'urql';
import { organizationDevicesQuery, OrganizationDevicesResult } from '@serenityapp/api-graphql';
import { SelectorItemProps } from '@serenityapp/components-react-common';

import { useCurrentUser } from '../../../common/hooks';
import { useMemo } from 'react';
import { OrganizationProps, Schema } from '@serenityapp/domain';

type OrganizationDeviceEdge = {
  id: string;
  organization: OrganizationProps;
  device: Schema.Device.Item;
};

const useLocationDevices = (locationDevices?: SelectorItemProps[]) => {
  const currentUser = useCurrentUser();
  const organizationId = currentUser?.orgId || '';

  const [result] = useQuery<OrganizationDevicesResult>({
    query: organizationDevicesQuery,
    variables: {
      getInput: { id: organizationId },
      pageInput: null,
    },
    requestPolicy: 'cache-and-network',
  });

  const { edges } = result?.data?.result?.devices || {};

  const allDevicesOptions: SelectorItemProps[] = useMemo(() => {
    return (
      edges
        ?.map((item: any) => ({
          id: item.device.id,
          label: item.device.name || '',
          subtitle: `Serial: ${item.device.serialNumber}`,
        }))
        .sort((a: SelectorItemProps, b: SelectorItemProps) => a.label.localeCompare(b.label)) ||
      []
    );
  }, [edges]);

  const unassignedDevices = result.data?.result.devices.edges.filter(
    (edge: OrganizationDeviceEdge) => !edge.device.location,
  );

  const unassignedDevicesOptions: SelectorItemProps[] = useMemo(() => {
    return (
      unassignedDevices?.map((item: any) => ({
        id: item.device.id,
        label: item.device.name || '',
        subtitle: `Serial: ${item.device.serialNumber}`,
      })) || []
    );
  }, [unassignedDevices]);

  // merge all unassigned devices with devices that are already assigned to the unit
  const devicesOptions = [...unassignedDevicesOptions, ...(locationDevices || [])].sort(
    (a: SelectorItemProps, b: SelectorItemProps) => a.label.localeCompare(b.label),
  );

  return {
    allDevicesOptions,
    devicesOptions,
    unassignedDevicesOptions,
    isFetching: result.fetching,
  };
};

export default useLocationDevices;
