import { Cache, ResolveInfo } from '@urql/exchange-graphcache';
import { Schema } from '@serenityapp/domain';
import { removeLocations } from './utils';
import { Unit } from '@serenityapp/api-graphql';

export default function unitRemove(
  result: Unit.Api.UnitRemoveMutationResult,
  _args: Schema.Unit.Remove.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const unitRemoveOutput = result?.result;

  if (info.error || !unitRemoveOutput || !unitRemoveOutput.success) {
    return;
  }

  const removedUnitId = _args.input.id;
  const organizationId = unitRemoveOutput.organizationId;
  const locationsToRemove = [removedUnitId];

  removeLocations(locationsToRemove, organizationId, cache);
}
