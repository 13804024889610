import { useDispatch } from 'react-redux';
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Switch,
  Typography,
} from '@mui/material';

import { StringFn } from '@serenityapp/core';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { ConversationMuteInput, ConversationNotificationKind } from '@serenityapp/domain';
import {
  ConversationSectionItemData,
  conversationMute,
  ConversationSectionData,
  notificationsSettingsDataTransformer,
  useViewerMemberOfConversations,
  NOTIFICATIONS_SETTINGS_SECTIONS_ORDER,
} from '@serenityapp/redux-store';

import {
  listItemTextSx,
  sectionHeaderSx,
  sectionListSx,
  switchSx,
} from './notificationSettingsStyles';
import NotificationSettingsDialogSkeleton from './NotificationSettingsSkeleton';

const NotificationSettings = () => {
  const makeTestId = useMakeTestId('NotificationSettingsDialog');

  const dispatch = useDispatch();

  const { data, isLoading } = useViewerMemberOfConversations();

  const { conversationPanelData, isEveryConversationMuted, allConversationsIds } =
    notificationsSettingsDataTransformer(data);

  if (isLoading) {
    return <NotificationSettingsDialogSkeleton />;
  }

  const toggleMuteConversation = (id: string, muted?: boolean) => {
    const conversationMuteInput: ConversationMuteInput = {
      conversationIds: [id],
      mute: muted ? [] : [ConversationNotificationKind.PUSH],
    };

    dispatch(conversationMute({ input: conversationMuteInput }));
  };

  const toggleMuteAllConversations = () => {
    const conversationMuteInput: ConversationMuteInput = {
      conversationIds: allConversationsIds,
      mute: isEveryConversationMuted ? [] : [ConversationNotificationKind.PUSH],
    };

    dispatch(conversationMute({ input: conversationMuteInput }));
  };

  const toggleMuteAllSection = (section: ConversationSectionData) => {
    const ids = section.data.map((conversation) => conversation.id);

    const conversationMuteInput: ConversationMuteInput = {
      conversationIds: ids,
      mute: section.isSectionMuted ? [] : [ConversationNotificationKind.PUSH],
    };

    dispatch(conversationMute({ input: conversationMuteInput }));
  };
  const renderItem = (item: ConversationSectionItemData, isSectionMuted: boolean) => (
    <ListItem
      key={`conversation-${item.id}`}
      disableGutters
      data-testid={makeTestId(item.name)}
      disabled={isEveryConversationMuted || isSectionMuted}
    >
      <ListItemText disableTypography sx={listItemTextSx}>
        <Typography noWrap title={item.name}>
          {item.name}
        </Typography>
      </ListItemText>
      <Switch
        checked={!item.muted && !isEveryConversationMuted && !isSectionMuted}
        disabled={isEveryConversationMuted || isSectionMuted}
        sx={switchSx}
        onChange={() => toggleMuteConversation(item.id, item.muted)}
      />
    </ListItem>
  );

  const renderSection = (section: ConversationSectionData) => (
    <List key={`conversation-section-${StringFn.generateKey(section.title)}`} sx={sectionListSx}>
      <ListSubheader disableGutters sx={sectionHeaderSx}>
        <Typography display="block" variant="overline">
          {section.title}
        </Typography>
      </ListSubheader>
      <Divider />
      <ListItem
        key={`mute-all-${StringFn.generateKey(section.title)}`}
        disableGutters
        data-testid={makeTestId(`mute-all-${StringFn.generateKey(section.title)}`)}
        disabled={isEveryConversationMuted}
      >
        <ListItemText primary={`Mute all ${section.title.toLowerCase()}`} />
        <Switch
          checked={section.isSectionMuted && !isEveryConversationMuted}
          disabled={isEveryConversationMuted}
          onChange={() => toggleMuteAllSection(section)}
        />
      </ListItem>
      {section.data.map((item) => renderItem(item, !!section.isSectionMuted))}
    </List>
  );

  return (
    <List>
      <ListItem key="mute-all" disableGutters data-testid={makeTestId('mute-all')}>
        <ListItemText
          primary="Mute all conversations"
          secondary="Mute or unmute all conversation you are a participant of."
        />
        <Switch checked={isEveryConversationMuted} onChange={toggleMuteAllConversations} />
      </ListItem>
      {NOTIFICATIONS_SETTINGS_SECTIONS_ORDER.map((sectionKind) => {
        const sectionData = conversationPanelData[sectionKind];

        return renderSection(sectionData);
      })}
    </List>
  );
};

export default NotificationSettings;
