import React from 'react';
import {
  Autocomplete,
  FormControl,
  TextField,
  Theme,
  SxProps,
  FormHelperText,
} from '@mui/material';
import { SelectorItemProps, useSerenityField } from '@serenityapp/components-react-common';
import { useMakeTestId } from '@serenityapp/components-react-web';

export type AutocompleteFieldProps = {
  items: SelectorItemProps[];
  dataTestId?: string;
  disabled?: boolean;
  disableSelectAll?: boolean;
  helperText?: string;
  loading?: boolean;
  label?: string;
  name: string;
  value?: SelectorItemProps | null;
  placeholder?: string;
  sx?: SxProps<Theme>;
  onChange: (event: React.SyntheticEvent, value: any, reason: string) => void;
};

const AutocompleteField = ({
  dataTestId,
  helperText,
  items = [],
  label,
  name,
  loading,
  onChange,
  value,
  sx,
}: AutocompleteFieldProps) => {
  const [, field, meta] = useSerenityField({ name });
  const fieldValue = field.value ?? value;

  const makeTestId = useMakeTestId('AutocompleteField', dataTestId);

  return (
    <FormControl fullWidth error={!!meta.error} variant="outlined">
      <Autocomplete
        disablePortal
        data-testid={dataTestId}
        getOptionLabel={(option: SelectorItemProps) => {
          return option.label || '';
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        loading={loading}
        options={items}
        renderInput={(params) => <TextField {...params} error={!!meta.error} label={label} />}
        renderOption={(props, option) => (
          <li
            {...props}
            data-testid={makeTestId(option.label)}
            key={`${option.label}-${option.id}`}
          >
            {option.label}
          </li>
        )}
        value={fieldValue}
        onChange={onChange}
      />
      <FormHelperText sx={helperTextMarginSx}>
        {meta.error?.toString() || helperText || ' '}
      </FormHelperText>
    </FormControl>
  );
};

const helperTextMarginSx = {
  mb: 2,
};

export default AutocompleteField;
