import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { useNavigate, useParams } from 'react-router-dom';

import { IdFn } from '@serenityapp/core';
import {
  ChannelCreateEditFormValues,
  SelectorItemProps,
} from '@serenityapp/components-react-common';
import { ConversationKind, ConversationSubjectKind, RouteParamId } from '@serenityapp/domain';
import {
  conversationUpdate,
  getIsChannelUpdating,
  getSelectorItemsAsConversationUsers,
} from '@serenityapp/redux-store';
import { useMakeTestId } from '@serenityapp/components-react-web';

import { ChannelCreateEditForm } from './components';
import { useConversationDetail } from './hooks';

const ChannelEditDrawer = () => {
  const makeTestId = useMakeTestId('ChannelEditDrawer');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams<RouteParamId>() as RouteParamId;

  const {
    administerableContactsAsSelectorItems,
    administerableLocationsAsSelectorItems,
    administerableStaffAsSelectorItems,
    conversation,
    isOwnerAdmin,
    isPartnerAdmin,
    isUserDetailLoading,
    isLoading,
    ownerName,
  } = useConversationDetail(id);
  const isUpdating = useSelector(getIsChannelUpdating);

  const isDetailLoading = isUserDetailLoading || isLoading;
  const ownershipInfo = ownerName ? `Shared channel (owner ${ownerName})` : `Shared channel`;

  const initialValues = {
    name: conversation?.name ?? '',
    description: conversation?.description ?? '',
    familyAndFriends: isOwnerAdmin ? administerableContactsAsSelectorItems : [],
    locations: administerableLocationsAsSelectorItems,
    staff: administerableStaffAsSelectorItems,
    isOneWayChannel: conversation?.isOneWayChannel ?? false,
  };

  const goBack = () => navigate('..');

  const handleFormSubmit = (values: ChannelCreateEditFormValues) => {
    const getFormDataAsInput = getSelectorItemsAsConversationUsers(
      id,
      ConversationKind.CHANNEL,
      ConversationSubjectKind.GENERAL,
      IdFn.encodeWellKnown('member'),
    );

    const conversationToUpdate = {
      id,
      name: values.name?.trim(),
      description: values.description?.trim(),
      staff: getFormDataAsInput(values.staff as Array<SelectorItemProps>),
      familyAndFriends: getFormDataAsInput(values.familyAndFriends as Array<SelectorItemProps>),
      locations: R.pluck('id', values.locations || []),
      isOneWayChannel: values.isOneWayChannel,
    };

    dispatch(
      conversationUpdate({
        conversation: conversationToUpdate,
        onFailed: goBack,
        onSuccess: goBack,
      }),
    );
  };

  return (
    <ChannelCreateEditForm
      isEditMode
      conversationId={conversation?.id}
      conversationName={conversation?.name}
      dataTestId={makeTestId('')}
      handleFormClose={goBack}
      handleFormSubmit={handleFormSubmit}
      initialValues={initialValues}
      isLoading={isDetailLoading}
      isOwnerAdmin={isOwnerAdmin}
      isSaving={isUpdating}
      ownershipInfo={isPartnerAdmin ? ownershipInfo : undefined}
      title={`Edit channel - ${conversation?.name}`}
    />
  );
};

export default ChannelEditDrawer;
