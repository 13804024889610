import { ZodError, ZodSchema } from 'zod';
import { FormikErrors, FormikValues } from 'formik';

type IndexedErrors = FormikErrors<any> & {
  [index: number]: string;
};

type FormikIndexedErrors = FormikErrors<FormikValues> & {
  [key: string]: string | IndexedErrors | FormikErrors<any> | undefined;
};

// Validate with Zod and handle nested errors
const validateWithZod =
  (schema: ZodSchema<any>) =>
  (values?: FormikValues): FormikIndexedErrors => {
    const errors: FormikIndexedErrors = {};
    if (values) {
      try {
        schema.parse(values);
      } catch (e) {
        if (e instanceof ZodError) {
          e.errors.forEach((error) => {
            // Check if this error is for an array field and includes a field index
            if (typeof error.path[1] === 'number') {
              const arrayFieldName = error.path[0] as string;
              const fieldIndex = error.path[1] as number;
              const errorMessage = error.message as string;

              // Initialize the error object for the array if it doesn't exist
              if (!errors[arrayFieldName]) {
                errors[arrayFieldName] = {};
              }

              // Ensure the storage for this index is initialized as an object
              if (!(errors[arrayFieldName] as IndexedErrors)[fieldIndex]) {
                (errors[arrayFieldName] as IndexedErrors)[fieldIndex] = errorMessage;
              }
            } else {
              // Handle non-array fields
              const errorKey = error.path.join('.');
              errors[errorKey] = error.message;
            }
          });
        }
      }
    }
    return errors;
  };

export default validateWithZod;
