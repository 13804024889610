import { Schema } from '@serenityapp/domain';
import { Cache, ResolveInfo } from '@urql/exchange-graphcache';
import { addLocation } from './utils';
import { ClientApi } from '@serenityapp/api-client-graph';

export default function locationGroupCreate(
  result: ClientApi.LocationGroup.Api.LocationGroupCreateMutationResult,
  _args: Schema.LocationGroup.Create.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const createdLocationGroupOutput = result?.result;

  if (info.error || !createdLocationGroupOutput || !createdLocationGroupOutput.success) {
    return;
  }

  const { organizationId, id, name, edited } = createdLocationGroupOutput.item;

  const location: Schema.Location.ItemWithTypename = {
    id,
    name,
    edited,
    kind: 'LocationGroup',
    displayName: 'Location Group',
    __typename: 'LocationGroup',
  };

  addLocation(location, organizationId, cache);
}
