import { useFormikContext, FormikValues } from 'formik';
import { Typography, Divider } from '@mui/material';

import { SearchSelectorField } from '@serenityapp/components-react-web';
import { useIsFeatureEnabled } from '@serenityapp/redux-store';

import { useLocationDevices } from '../hooks';
import { UnitFormValues } from '../schema';
import { dividerSx } from './styles';

const DeviceSearchSelectorField = () => {
  const { initialValues } = useFormikContext<UnitFormValues>();
  const { devices } = initialValues as FormikValues;

  const isDevicesFeatureEnabled = useIsFeatureEnabled('devices');

  const { devicesOptions, isFetching } = useLocationDevices(devices);

  return (
    <>
      {isDevicesFeatureEnabled ? (
        <>
          <Typography display="block" variant="overline" sx={typographySx}>
            Devices
          </Typography>
          <Divider sx={dividerSx} />
          <SearchSelectorField
            items={devicesOptions}
            label="Devices"
            name="devices"
            loading={isFetching}
          />
        </>
      ) : null}
    </>
  );
};

const typographySx = { mt: 3 };

export default DeviceSearchSelectorField;
