import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { EnumFn, IdFn, PhoneNumberFn, ServiceLevel, StringFn } from '@serenityapp/core';
import { UserCreateEditFormValues } from '@serenityapp/components-react-common';
import { useMakeTestId } from '@serenityapp/components-react-web';
import {
  ConversationKind,
  ConversationSubjectKind,
  RouteParamId,
  UserType,
  UserTypeFn,
} from '@serenityapp/domain';
import { snackAdd, userUpdate } from '@serenityapp/redux-store';

import { UserCreateEditForm } from './components';
import { useUserDetail } from './hooks';
import { useCurrentUser } from '../common/hooks';

const UserEditDrawer = () => {
  const makeTestId = useMakeTestId('UserEditDrawer');
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const orgId = currentUser?.orgId;
  const { id } = useParams<RouteParamId>() as RouteParamId;

  const {
    user,
    generalChannels: currentGeneralChannels,
    residentChannels: currentResidentChannels,
    isLoading,
    isUpdating,
  } = useUserDetail(id);

  const initialValues = {
    createAccount: false,
    department: user?.department ?? '',
    description: user?.description ?? '',
    email: user?.email ?? '',
    fullName: user?.fullName,
    lastName: user?.lastName,
    firstName: user?.firstName,
    generalChannels: currentGeneralChannels,
    jobTitle: user?.jobTitle ?? '',
    userName: user?.name,
    phoneNumber: PhoneNumberFn.getNationalNumber(user?.phoneNumber) || '',
    residentChannels: currentResidentChannels,
    userType: user?.subsubtype ? user?.subsubtype : user?.subtype,
    workerCategory: user?.workerCategory ?? '',
    serviceLevel:
      user?.serviceLevel &&
      user?.serviceLevel !== ServiceLevel.UNKNOWN &&
      user?.serviceLevel !== ServiceLevel.NONE
        ? user?.serviceLevel
        : '',
    checkIn: !user?.checkInOptOut,
    room: user?.room?.id || undefined,
  };

  const goBack = () => navigate('..');

  const handleFormSubmit = ({
    createAccount,
    department,
    description,
    email,
    familyAndFriends,
    firstName,
    lastName,
    generalChannels,
    jobTitle,
    phoneNumber,
    residentChannels,
    staff,
    userType,
    workerCategory,
    serviceLevel,
    checkIn,
    room,
  }: UserCreateEditFormValues) => {
    const userKind = UserTypeFn.toKind(userType as UserType);
    const isUserResident = userType === 'resident';
    const isCreateAccount = !isUserResident && createAccount;

    const selectedGeneralChannels = isUserResident ? [] : generalChannels;
    const selectedResidentChannels = isUserResident ? [] : residentChannels;
    const selectedFamilyAndFriends = !isUserResident ? [] : familyAndFriends;
    const selectedStaff = !isUserResident ? [] : staff;

    const residentConversationId = IdFn.new();
    const memberRoleId = IdFn.fromString('member');

    const fullName = `${firstName?.trim()} ${lastName?.trim()}`;

    // This component is used when org has `removeUsernames` feature flag enabled.
    // In this case, in form, we only have input fields for `firstName` and `lastName`.
    // We can't set userName thru this form, which is correct, but some older organizations
    // still use usernames as a primary user identifier, so we need to at least programmatically
    // set it up.

    // We had an issue in cross org where one org had `removeUsernames` feature flag enabled and
    // another one didn't. This meant when a name was changed in the org with `removeUsernames`
    // the userName won't update, but will still be displayed in the org that didn't have the flag.
    const userName = fullName.toLocaleLowerCase().split(' ').join('.');

    const userToUpdate = {
      id,
      email,
      orgId,
      isCreateAccount,
      checkInOptOut: !checkIn,
      serviceLevel: !serviceLevel ? ServiceLevel.NONE : EnumFn.parse(ServiceLevel, serviceLevel),
      phoneNumber: PhoneNumberFn.getInternationalNumber(phoneNumber),
      kind: userKind,
      department: department ? department?.trim() : undefined,
      description: description?.trim(),
      fullName,
      initials: StringFn.initials(fullName),
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      userName: isUserResident ? fullName : userName,
      jobTitle: jobTitle ? jobTitle?.trim() : undefined,
      workerCategory: workerCategory ? workerCategory?.trim() : undefined,
      roomId: room || null,
      residentChannels: selectedResidentChannels?.map((item) => ({
        conversationId: item.id,
        kind: ConversationKind.CHANNEL,
        subject: ConversationSubjectKind.RESIDENT,
        roleId: memberRoleId,
        userId: id,
      })),
      staff: selectedStaff?.map((item) => ({
        conversationId: residentConversationId,
        kind: ConversationKind.CHANNEL,
        roleId: memberRoleId,
        subject: ConversationSubjectKind.RESIDENT,
        userId: item.id,
      })),
      familyAndFriends: selectedFamilyAndFriends?.map((item) => ({
        conversationId: residentConversationId,
        kind: ConversationKind.CHANNEL,
        roleId: memberRoleId,
        subject: ConversationSubjectKind.RESIDENT,
        userId: item.id,
      })),
      generalChannels: selectedGeneralChannels?.map((item) => ({
        conversationId: item.id,
        kind: ConversationKind.CHANNEL,
        subject: ConversationSubjectKind.GENERAL,
        roleId: memberRoleId,
        userId: id,
      })),
    };

    dispatch(
      userUpdate({
        variables: { input: userToUpdate },
        onFailed: () => {
          dispatch(snackAdd({ message: 'Error updating user', type: 'error' }));
        },
        onSuccess: () => {
          dispatch(snackAdd({ message: 'User successfully updated', type: 'success' }));
          goBack();
        },
      }),
    );
  };

  return (
    <UserCreateEditForm
      isEditMode
      dataTestId={makeTestId('')}
      handleFormClose={goBack}
      handleFormSubmit={handleFormSubmit}
      initialValues={initialValues}
      isLoading={isLoading || isUpdating}
      isSaving={isUpdating}
      title={`Edit user - ${user?.fullName}`}
      user={user}
    />
  );
};

export default UserEditDrawer;
