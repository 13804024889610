import { SyntheticEvent, useEffect, useState } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { Divider, Typography } from '@mui/material';

import { FormikField, SelectField, useMakeTestId } from '@serenityapp/components-react-web';
import { SelectorItemProps } from '@serenityapp/components-react-common';
import { ServiceLevel } from '@serenityapp/core';

import { transformServiceLevelsToSelectorItemProps } from '../../../common/utils';
import { MULTI_PURPOSE_SERVICE_LEVEL } from '../utils';
import { UnitFormValues } from '../schema';
import { useBuildings, useFloors, useLocationGroups, useUsers } from '../hooks';

import AddressFields from './AddressFields';
import AutocompleteField from './AutocompleteField';
import ServiceLevelMismatchDialog from './ServiceLevelMismatchDialog';
import UserSearchSelectorField from './UserSearchSelectorField';
import { dividerSx } from './styles';
import DeviceSearchSelectorField from './DeviceSearchSelectorField';

const serviceLevels: SelectorItemProps[] = transformServiceLevelsToSelectorItemProps(
  Object.values(ServiceLevel),
).concat([{ id: MULTI_PURPOSE_SERVICE_LEVEL, label: 'Multi-purpose' }]);

type UnitFieldsProps = {
  isEditMode: boolean;
};

const UnitFields = ({ isEditMode = false }: UnitFieldsProps) => {
  const { setFieldValue, initialValues } = useFormikContext<UnitFormValues>();

  const { floorId, buildingId, pccRecordStamp, locationGroupId } = initialValues as FormikValues;

  const makeTestId = useMakeTestId('UnitFields');

  const [selectedLocationGroupId, setSelectedLocationGroupId] = useState(locationGroupId);
  const [selectedBuildingId, setSelectedBuildingId] = useState(buildingId);
  const [selectedFloorId, setSelectedFloorId] = useState(floorId);

  const { locationGroupOptions, locationGroupsFetching, locationGroupBuildingsSelectItems } =
    useLocationGroups(selectedLocationGroupId);

  useEffect(() => {
    if (initialValues) {
      setSelectedLocationGroupId(locationGroupId);
      setSelectedBuildingId(buildingId);
      setSelectedFloorId(floorId);
    }
  }, [initialValues, locationGroupId, buildingId, floorId]);

  // Buildings logic
  const { buildingsFetching, buildingOptions, selectedBuilding } =
    useBuildings(selectedBuildingId);

  // Floors logic
  const { floorsOptions } = useFloors(selectedBuilding);

  // Fetch users
  const { usersFetching, usersOptions } = useUsers();

  const selectedBuildingItem = buildingOptions.find(
    (item: SelectorItemProps) => item.id === selectedBuildingId,
  );

  const selectedFloorItem = floorsOptions.find(
    (item: SelectorItemProps) => item.id === selectedFloorId,
  );

  const selectedLocationGroupItem: SelectorItemProps | undefined = locationGroupOptions.find(
    (item: SelectorItemProps) => item.id === selectedLocationGroupId,
  );

  const clearFloorInput = () => {
    setSelectedFloorId(undefined);
    setFieldValue('floor', null);
    setFieldValue('floorId', null);
  };

  const clearBuildingInput = () => {
    setFieldValue('building', null);
    setFieldValue('buildingId', null);
    setSelectedBuildingId(undefined);
  };

  const clearLocationGroupInput = () => {
    setFieldValue('locationGroup', null);
    setFieldValue('locationGroupId', null);
    setSelectedLocationGroupId(undefined);
  };

  const handleLocationGroupChange = (
    event: SyntheticEvent,
    value: SelectorItemProps,
    reason: string,
  ) => {
    if (reason === 'clear') {
      clearFloorInput();
      clearBuildingInput();
      clearLocationGroupInput();

      return;
    }
    setFieldValue('locationGroup', value);
    setFieldValue('locationGroupId', value?.id);
    setSelectedLocationGroupId(value?.id || null);

    // clear up building and floor inputs
    clearBuildingInput();
    clearFloorInput();
  };

  const handleBuildingChange = (
    event: SyntheticEvent,
    value: SelectorItemProps,
    reason: string,
  ) => {
    if (reason === 'clear') {
      clearFloorInput();
      clearBuildingInput();
      return;
    }

    setFieldValue('building', value);
    setFieldValue('buildingId', value?.id);
    setSelectedBuildingId(value?.id || null);
    clearFloorInput();
  };

  const handleFloorChange = (event: SyntheticEvent, value: SelectorItemProps, reason: string) => {
    if (reason === 'clear') {
      clearFloorInput();
      return;
    }

    setFieldValue('floor', value);
    setFieldValue('floorId', value?.id);
    setSelectedFloorId(value?.id || null);
  };

  return (
    <>
      {isEditMode && pccRecordStamp && (
        <FormikField
          dataTestId={makeTestId('PCCRecordStamp')}
          disabled
          label="PointClickCare record type"
          name="pccRecordStamp"
          type="text"
        />
      )}
      <FormikField
        dataTestId={makeTestId('DisplayName')}
        helperText="Overwrite default location type name"
        label="Display name"
        name="displayName"
        type="text"
      />
      <SelectField
        dataTestId={makeTestId('ServiceLevel')}
        items={serviceLevels}
        label="Service level"
        name="serviceLevel"
      />
      <AutocompleteField
        dataTestId={makeTestId('LocationGroup')}
        items={locationGroupOptions}
        label="Location Group"
        name="locationGroup"
        value={selectedLocationGroupItem || null}
        onChange={handleLocationGroupChange}
        loading={locationGroupsFetching}
      />
      <AutocompleteField
        dataTestId={makeTestId('Building')}
        items={
          locationGroupBuildingsSelectItems?.length === 0 && !selectedLocationGroupItem
            ? buildingOptions
            : locationGroupBuildingsSelectItems
        }
        label="Building"
        name="building"
        value={selectedBuildingItem || null}
        onChange={handleBuildingChange}
        loading={buildingsFetching}
      />
      <AutocompleteField
        dataTestId={makeTestId('Floor')}
        items={floorsOptions}
        label="Floor"
        name="floor"
        value={selectedFloorItem || null}
        onChange={handleFloorChange}
      />
      <Typography display="block" variant="overline">
        residents
      </Typography>
      <Divider sx={dividerSx} />
      <UserSearchSelectorField loading={usersFetching} usersOptions={usersOptions} />
      <DeviceSearchSelectorField />
      <AddressFields />
      <ServiceLevelMismatchDialog />
    </>
  );
};

export default UnitFields;
