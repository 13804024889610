import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import * as R from 'ramda';

import { GroupCreateEditFormValues } from '@serenityapp/components-react-common';
import {
  PermissionFieldInput,
  permissionNames,
  PermissionSetFn,
  RouteParamId,
  StandardIAMGroups,
  UserFn,
  UserProps,
} from '@serenityapp/domain';
import {
  getIsIAMGroupUpdating,
  iamGroupUpdate,
  mapUserItemsToSelectorItems,
  useIsFeatureEnabled,
} from '@serenityapp/redux-store';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { PermissionValue } from '@serenityapp/core';

import { GroupCreateEditForm } from './components';
import { useGroupDetail } from './hooks';

const GroupEditDrawer = () => {
  const makeTestId = useMakeTestId('GroupEditDrawer');
  const dispatch = useDispatch();

  const isRemoveUsernamesFeatureEnabled = useIsFeatureEnabled('removeUsernames');

  const navigate = useNavigate();

  const { id } = useParams<RouteParamId>() as RouteParamId;

  const goBack = () => navigate('..');

  const { group, isLoading } = useGroupDetail(id);
  const isGroupUpdating: boolean = useSelector(getIsIAMGroupUpdating);

  const users: UserProps[] = (group?.users?.edges ?? []).map((edge) => edge.node);

  const staffGroupUsers = R.filter(UserFn.isStaff, users);
  const contactsGroupUsers = R.filter(UserFn.isContact, users);
  const adminGroupUsers = R.filter(UserFn.isAdmin, users);

  const staffSelectorItems = mapUserItemsToSelectorItems(isRemoveUsernamesFeatureEnabled)(
    staffGroupUsers,
  );
  const contactsSelectorItems = mapUserItemsToSelectorItems(isRemoveUsernamesFeatureEnabled)(
    contactsGroupUsers,
  );
  const adminSelectorItems = mapUserItemsToSelectorItems(isRemoveUsernamesFeatureEnabled)(
    adminGroupUsers,
  );

  const initialValues = {
    groupType: group?.name && group?.name in StandardIAMGroups ? 'standard' : 'custom',
    name: group?.name,
    description: group?.description ?? '',
    staff: staffSelectorItems,
    contacts: contactsSelectorItems,
    ...PermissionSetFn.getPermissionValues(group?.permissionSet),
  };

  const handleFormSubmit = ({
    contacts,
    description,
    name,
    staff,
    groupType,
    ...permissionValues
  }: GroupCreateEditFormValues) => {
    const permissions: ReadonlyArray<PermissionFieldInput> = permissionNames.map(
      (permissionName: string) => ({
        name: permissionName,
        value:
          (permissionValues as Record<string, PermissionValue | undefined>)[permissionName] ??
          PermissionValue.INDETERMINATE,
      }),
    );
    const mergedUsers = adminSelectorItems.concat(staff).concat(contacts);
    const users = R.pluck('id', mergedUsers);

    const groupToUpdate = {
      id,
      name,
      users,
      description,
      orgId: group?.orgId,
      permissionSet: {
        id: group?.permissionSet?.id,
        permissions,
      },
    };

    dispatch(
      iamGroupUpdate({
        group: groupToUpdate,
        onFailed: goBack,
        onSuccess: goBack,
      }),
    );
  };

  return (
    <GroupCreateEditForm
      isEditMode
      dataTestId={makeTestId('')}
      groupId={group?.id}
      groupName={group?.name}
      handleFormClose={goBack}
      handleFormSubmit={handleFormSubmit}
      initialValues={initialValues}
      isLoading={isLoading}
      isSaving={isGroupUpdating}
      title={`Edit group - ${group?.name}`}
    />
  );
};

export default GroupEditDrawer;
